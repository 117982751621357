import React, { useState, useContext } from 'react'
import { DataContext } from '../DataContext.jsx'
import { auth, updatePassword } from '../firebase/firebaseConfig'
import Message from '../components/Message.jsx'
import Register from '../components/Register.jsx'
import { PlusIcon, LogoutIcon } from '../components/Icons'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  margin-top: 50px;
  justify-content: center;
  height: 70vh;
`

const NewUser = styled(PlusIcon)`
  top: 50px;
  right: 50px;
  position: absolute;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    right: 15px;
  }
`
const Logout = styled(LogoutIcon)`
  bottom: 110px;
  right: 50px;
  position: absolute;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    right: 15px;
  }
`

const Form = styled.div`
  background-color: hsla(193, 40%, 10%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px hsla(0, 0%, 0%, 0.5);
  width: 450px;
  max-width: 60%;
  min-width: 380px;

  & > div {
    display: flex;
    flex-direction: column;
    width: 85%;

    & label {
      font-size: 18px;
      font-weight: 500;
      color: #eef;
    }

    & input[type='email'],
    input[type='password'] {
      border-radius: 4px;
      padding: 10px;
      font-size: 1.2rem;
      color: #eef;
      outline: none;
      border: 2px solid hsla(0, 0%, 100%, 0.5) !important;
      background-color: transparent;
      margin: 3px 0 5px;
    }

    & input[type='email'] {
      color: #ddd;
    }
  }

  & button {
    width: 85%;
    padding: 10px 16px;
    border-radius: 6px;
    border: none;
    background-color: hsl(193, 65%, 50%);
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    cursor: pointer;
  }
`

export default function User() {
  const { user, setUser } = useContext(DataContext)
  const [pass, setPass] = useState('')
  const [pass2, setPass2] = useState('')
  const [modalNew, setModalNew] = useState(false)

  const handleLogout = () => {
    auth.signOut()
    Message('Sesión cerrada.', 'sing out', 'success')
    setUser(null)
    localStorage.removeItem('user')
    localStorage.removeItem('token')
  }

  const updated = () => {
    if (pass !== pass2) {
      return Message('Ambas contraseñas deben ser la misma.', 'not equal', 'error')
    }
    if (/^\s*$/.test(pass)) {
      return Message('Tienes que escribir una contraseña.', 'void', 'error')
    }
    const u = auth.currentUser

    updatePassword(u, pass)
      .then(() => {
        Message('Contraseña actualizada.', 'sing out', 'success')
        setPass('')
        setPass2('')
      })
      .catch(function (error) {
        if (error.code === 'auth/requires-recent-login') {
          return Message('paso algo', 'sing out', 'error')
        }
        Message(error.message, 'sing out', 'error')
      })
  }

  return (
    <Wrapper>
      {modalNew && <Register state={modalNew} setState={setModalNew} />}

      <NewUser width='36' height='36' color='#fff' onClick={() => setModalNew(true)} />
      <Logout width='36' height='36' color='#fff' onClick={handleLogout} />

      <Form>
        <div>
          <label>Correo:</label>
          <input type='email' disabled value={user.email} />
        </div>
        <div>
          <label>Contraseña:</label>
          <input
            type='password'
            placeholder='Contraseña'
            value={pass}
            onChange={({ target: { value } }) => setPass(value)}
          />
        </div>
        <div>
          <label>Repetir contraseña:</label>
          <input
            type='password'
            placeholder='Contraseña'
            value={pass2}
            onChange={({ target: { value } }) => setPass2(value)}
          />
        </div>
        <button onClick={() => updated()}>Actualizar contraseña</button>
      </Form>
    </Wrapper>
  )
}

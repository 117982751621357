import React, { useState, useContext } from 'react'
import { DataContext } from '../DataContext.jsx'
import { auth } from '../firebase/firebaseConfig'
import { signInWithEmailAndPassword } from 'firebase/auth'
// toat
import Message from '../components/Message.jsx'
import 'react-toastify/dist/ReactToastify.css'
import style from '../styles/login.module.css'

function Login() {
  const { user, setUser } = useContext(DataContext)
  const [emailLogin, setEmailLogin] = useState('')
  const [passLogin, setPassLogin] = useState('')

  function login() {
    // Message('No puedes logearte, bacotips iniciara el dia 20 de abril.', 'void', 'warning');
    // return;
    if (user) {
      Message('Ya has iniciado sesion, por favor recargue la pagina.')
      return
    }

    if (emailLogin === '' || passLogin === '') {
      Message('Por favor ingrese su correo y contraseña.', 'void', 'warning')
      return
    }

    signInWithEmailAndPassword(auth, emailLogin, passLogin)
      .then(userCredential => {
        Message('Bienvenid@', 'success', 'success')
        setUser(userCredential.user)
      })
      .catch(error => {
        if (error.code === 'auth/wrong-password') {
          return Message('La contraseña es incorrecta.', 'wrong-password', 'warning')
        }

        if (error.code === 'auth/user-not-found') {
          return Message('El usuario no existe.', 'user-not-found', 'warning')
        }

        Message('Ocurrio un error, por favor intente nuevamente.', 'error', 'error')
      })
  }

  return (
    <div className={style.overlay}>
      <div className={style.modal} id='login'>
        <div className={style.modal_header}>
          <h2>Iniciar Sesion</h2>
        </div>

        <div className={style.modal_body}>
          <div className={style.modal_field}>
            <label htmlFor='emailLogin'>Correo Electronico</label>
            <input type='email' id='emailLogin' value={emailLogin} onChange={e => setEmailLogin(e.target.value)} />
          </div>
          <div className={style.modal_field}>
            <label htmlFor='passLogin'>Contraseña</label>
            <input type='password' id='passLogin' value={passLogin} onChange={e => setPassLogin(e.target.value)} />
          </div>
          <button onClick={login}>Iniciar sesion</button>
        </div>
      </div>
    </div>
  )
}

export default Login

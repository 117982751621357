import React, { useState, useEffect, useContext } from 'react'
import { DataContext } from '../DataContext.jsx'

import Admins from '../components/Admins.jsx'
import Employees from '../components/Employees.jsx'
import Materials from '../components/Materials.jsx'
import Production from '../components/Production.jsx'

export default function Registers() {
  const {
    admins,
    setAdmins,
    employees,
    setEmployees,
    materials,
    setMaterials,
    production,
    setProduction,
    previewStorage,
  } = useContext(DataContext)
  const [check, setCheck] = useState(-1)

  useEffect(() => {
    // verificar si ya hay datos en el storage
    previewStorage('admins', setAdmins, admins)
    previewStorage('employees', setEmployees, employees)
    previewStorage('materials', setMaterials, materials)
    previewStorage('production', setProduction, production)
  }, [])

  // actualizacion de localStorage
  useEffect(() => {
    localStorage.setItem('admins', JSON.stringify(admins))
  }, [admins])
  useEffect(() => {
    localStorage.setItem('employees', JSON.stringify(employees))
  }, [employees])
  useEffect(() => {
    localStorage.setItem('materials', JSON.stringify(materials))
  }, [materials])
  useEffect(() => {
    localStorage.setItem('production', JSON.stringify(production))
  }, [production])

  return (
    <div className='content'>
      <div className='options'>
        <div>
          <input
            type='radio'
            name='databse'
            id='admins'
            className='option'
            onClick={() => setCheck(0)}
            checked={check === 0}
          />
          <label htmlFor='admins' className='option'>
            Administradores
          </label>
        </div>
        <div>
          <input
            type='radio'
            name='databse'
            id='employees'
            className='option'
            onClick={() => setCheck(1)}
            checked={check === 1}
          />
          <label htmlFor='employees' className='option'>
            Empleados
          </label>
        </div>
        <div>
          <input
            type='radio'
            name='databse'
            id='materials'
            className='option'
            onClick={() => setCheck(2)}
            checked={check === 2}
          />
          <label htmlFor='materials' className='option'>
            Materiales
          </label>
        </div>
        <div>
          <input
            type='radio'
            name='databse'
            id='production'
            className='option'
            onClick={() => setCheck(3)}
            checked={check === 3}
          />
          <label htmlFor='production' className='option'>
            Produccion
          </label>
        </div>
      </div>

      {check === 0 ? (
        <Admins />
      ) : check === 1 ? (
        <Employees />
      ) : check === 2 ? (
        <Materials />
      ) : check === 3 ? (
        <Production />
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            textAlign: 'center',
            height: '70vh',
            fontSize: '26px',
          }}
        >
          por favor selecciona una vista.
        </div>
      )}
      <br />
      <br />
      <br />
    </div>
  )
}

import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  height: 100%;
`

export default function TabPanel(props) {
  const { children, value, index } = props

  return <>{value === index && <Container>{children}</Container>}</>
}

import React, { useState, useEffect, useContext } from 'react'
import { DataContext } from '../DataContext.jsx'
import { auth, onAuthStateChanged } from '../firebase/firebaseConfig'
// Iconos
import { HomeIcon, RegisterIcon, UserIcon } from '../components/Icons'
// Componentes
import TabPanel from '../components/TabPanel'
import Login from '../components/Login.jsx'
// Paginas
import Home from './Home.jsx'
import Registers from './Registers.jsx'
import User from './User.jsx'
import styled from 'styled-components'

const AppWeapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: hsl(193, 10%, 25%);
  justify-content: center;
`

const PanelContent = styled.div`
  height: calc(100vh - 74px);
`

const Tabs = styled.div`
  background: #2e3638;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  padding-top: 10px;
  height: 74px;
  width: 100vw;
`

const TabStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  width: 33%;
  color: ${({ index, value }) => (index === value ? '#fafafa' : '#CFCFCF')};
  ${({ index, value }) => index === value && 'border-bottom: 5px solid #00C8FF;'}
`

const Tab = props => {
  const { label, icon: Icon, index, value, setValue } = props

  return (
    <TabStyle onClick={() => setValue(index)} index={index} value={value}>
      <Icon width='32' height='32' color={index === value ? '#fafafa' : '#CFCFCF'} />
      <p>{label}</p>
    </TabStyle>
  )
}

function App() {
  const { user, setUser } = useContext(DataContext)
  const [value, setValue] = useState(2)

  useEffect(() => {
    onAuthStateChanged(auth, async u => {
      setUser(u)
    })
  }, [])

  return (
    <AppWeapper>
      {!user ? (
        <Login />
      ) : (
        <>
          <PanelContent>
            <TabPanel index={1} value={value}>
              <Home />
            </TabPanel>
            <TabPanel index={2} value={value}>
              <Registers />
            </TabPanel>
            <TabPanel index={3} value={value}>
              <User />
            </TabPanel>
          </PanelContent>
          <Tabs>
            <Tab index={1} value={value} setValue={setValue} icon={HomeIcon} label='INICIO' />
            <Tab index={2} value={value} setValue={setValue} icon={RegisterIcon} label='REGISTROS' />
            <Tab index={3} value={value} setValue={setValue} icon={UserIcon} label='CUENTA' />
          </Tabs>
        </>
      )}
    </AppWeapper>
  )
}

export default App

import React, { createContext, useState } from 'react'
import { db } from './firebase/firebaseConfig'
import { ref, onValue } from 'firebase/database'

export const DataContext = createContext()

export const DataProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [showLoginAndRegister, setShowLoginAndRegister] = useState('')

  const [admins, setAdmins] = useState([])
  const [employees, setEmployees] = useState([])
  const [materials, setMaterials] = useState([])
  const [production, setProduction] = useState([])

  const previewStorage = (registers, setState, thisState) => {
    if (localStorage.getItem(registers)) {
      // cargar los datos del storage al state
      const items = JSON.parse(localStorage.getItem(registers))
      setState(items)
    } else {
      // guardar los datos en los estados
      database(`${registers}/`, setState, thisState)
    }
  }

  const database = (field, setState) => {
    onValue(
      ref(db, field),
      snapshot => {
        const keys = Object.keys(snapshot.val())
        keys.forEach(key => {
          const data = snapshot.val()[key]
          data.id = key
          setState(thisState => [...thisState, data])
        })
      },
      {
        onlyOnce: true,
      },
    )
  }

  const reloadAdnmins = () => {
    // limpiar storage
    localStorage.removeItem('admins')
    // guardar los datos en los estados
    setAdmins([])
    database(`admins/`, setAdmins)
  }

  const reloadEmployees = () => {
    // limpiar storage
    localStorage.removeItem('employees')
    // guardar los datos en los estados
    setEmployees([])
    database(`employees/`, setEmployees)
  }

  const reloadMaterials = () => {
    // limpiar storage
    localStorage.removeItem('materials')
    // guardar los datos en los estados
    setMaterials([])
    database(`materials/`, setMaterials)
  }

  const reloadProduction = () => {
    // limpiar storage
    localStorage.removeItem('production')
    // guardar los datos en los estados
    setProduction([])
    database(`production/`, setProduction)
  }

  return (
    <DataContext.Provider
      value={{
        user,
        setUser,
        showLoginAndRegister,
        setShowLoginAndRegister,
        admins,
        setAdmins,
        employees,
        setEmployees,
        materials,
        setMaterials,
        production,
        setProduction,
        previewStorage,
        reloadAdnmins,
        reloadEmployees,
        reloadMaterials,
        reloadProduction,
      }}
    >
      {children}
    </DataContext.Provider>
  )
}

import { toast, Slide } from 'react-toastify'

toast.configure()

export default function Message(text, id = 'loginInvalid', type = toast.TYPE.WARNING) {
  return toast.warn(text, {
    position: toast.POSITION.BOTTOM_LEFT,
    autoClose: 8000,
    theme: 'dark',
    toastId: id,
    type,
    transition: Slide,
  })
}

import React, { useState } from 'react'
// import { useFirebaseApp } from 'firebase'
import { auth } from '../firebase/firebaseConfig'
// import { setDoc, doc, getDocs, getDoc, collection, query, where } from 'firebase/firestore'
import { createUserWithEmailAndPassword } from 'firebase/auth'

import Message from '../components/Message.jsx'
import 'react-toastify/dist/ReactToastify.css'
import style from '../styles/login.module.css'

export default function Register(props) {
  const { state, setState } = props
  const [emailLogin, setEmailLogin] = useState('')
  const [passLogin, setPassLogin] = useState('')

  const newUser = async () => {
    createUserWithEmailAndPassword(auth, emailLogin, passLogin)
      .then(res => {
        Message('Bienvenid@ ' + emailLogin, 'register-success', 'success')
        setEmailLogin('')
        setPassLogin('')
      })
      .catch(err => {
        if (err.code === 'auth/weak-password') {
          Message(
            'La contraseña es muy debil,  debe tener una longitud minima de 6 caracteres.',
            'weak-password',
            'warning',
          )
        } else if (err.code === 'auth/email-already-in-use') {
          Message('El correo ya esta en uso.', 'email-already-in-use', 'warning')
        } else if (err.code === 'auth/invalid-email') {
          Message('El correo no es valido.', 'invalid-email', 'warning')
        } else {
          Message('Ocurrio un error, por favor revise los campos de correo y contraseña.', 'register-error', 'warning')
          console.error(err)
          state && <></> // borrar
        }
      })
  }

  return (
    <div className={style.overlay5}>
      <div className={style.modal} id='login'>
        <div className={style.modal_header}>
          <h2>Registrar nuevo administrador</h2>
          <div className={style.close} onClick={() => setState(false)}>
            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
              <path d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z' />
            </svg>
          </div>
        </div>

        <div className={style.modal_body}>
          <div className={style.modal_field}>
            <label htmlFor='emailLogin'>Correo Electronico</label>
            <input type='email' id='emailLogin' value={emailLogin} onChange={e => setEmailLogin(e.target.value)} />
          </div>
          <div className={style.modal_field}>
            <label htmlFor='passLogin'>Contraseña</label>
            <input type='password' id='passLogin' value={passLogin} onChange={e => setPassLogin(e.target.value)} />
          </div>
          <button onClick={newUser}>Crear Cuenta</button>
        </div>
      </div>
    </div>
  )
}

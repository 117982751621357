import React from 'react'
import styled from 'styled-components'

const HomeWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

export default function Home() {
  return (
    <HomeWrapper>
      <a
        href='https://firebasestorage.googleapis.com/v0/b/geeoplastic.appspot.com/o/program%2FGeeo%20Production%20Setup.exe?alt=media&token=44352a8e-c6c1-4169-ac43-f92981a2c548'
        className='btn'
      >
        Descarga la App de escritorio
      </a>
    </HomeWrapper>
  )
}

import React from 'react'

const HomeIcon = props => {
  const { color = '', ...rest } = props
  return (
    <svg viewBox='0 0 24 24' aria-hidden='true' {...rest}>
      <path fill={color} d='M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z' />
    </svg>
  )
}

const RegisterIcon = props => {
  const { color = '', ...rest } = props
  return (
    <svg viewBox='0 0 24 24' aria-hidden='true' {...rest}>
      <path
        fill={color}
        d='M11.5 9C10.12 9 9 10.12 9 11.5s1.12 2.5 2.5 2.5 2.5-1.12 2.5-2.5S12.88 9 11.5 9zM20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-3.21 14.21l-2.91-2.91c-.69.44-1.51.7-2.39.7C9.01 16 7 13.99 7 11.5S9.01 7 11.5 7 16 9.01 16 11.5c0 .88-.26 1.69-.7 2.39l2.91 2.9-1.42 1.42z'
      />
    </svg>
  )
}

const UserIcon = props => {
  const { color = '', ...rest } = props
  return (
    <svg viewBox='0 0 24 24' aria-hidden='true' {...rest}>
      <path
        fill={color}
        d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z'
      />
    </svg>
  )
}

const PlusIcon = props => {
  const { color = '', ...rest } = props
  return (
    <svg viewBox='0 0 24 24' aria-hidden='true' {...rest}>
      <path
        fill={color}
        d='M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z'
      />
    </svg>
  )
}

const LogoutIcon = props => {
  const { color = '', ...rest } = props
  return (
    <svg viewBox='0 0 24 24' aria-hidden='true' {...rest}>
      <path
        fill={color}
        d='M10.09 15.59L11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59zM19 3H5c-1.11 0-2 .9-2 2v4h2V5h14v14H5v-4H3v4c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z'
      />
    </svg>
  )
}

const ReloadIcon = props => {
  const { color = '', ...rest } = props
  return (
    <svg viewBox='0 0 24 24' aria-hidden='true' {...rest}>
      <path
        fill={color}
        d='M19 8l-4 4h3c0 3.31-2.69 6-6 6-1.01 0-1.97-.25-2.8-.7l-1.46 1.46C8.97 19.54 10.43 20 12 20c4.42 0 8-3.58 8-8h3l-4-4zM6 12c0-3.31 2.69-6 6-6 1.01 0 1.97.25 2.8.7l1.46-1.46C15.03 4.46 13.57 4 12 4c-4.42 0-8 3.58-8 8H1l4 4 4-4H6z'
      />
    </svg>
  )
}

export { HomeIcon, RegisterIcon, UserIcon, PlusIcon, LogoutIcon, ReloadIcon }

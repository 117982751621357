import React from 'react'
import { createRoot } from 'react-dom/client'
import { DataProvider } from './DataContext.jsx'
import './styles/App.css'
import App from './views/App.jsx'
import * as serviceWorker from './serviceWorkerRegistration'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <DataProvider>
    <App />
  </DataProvider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.register()

import React, { useState, useContext } from 'react'
import { DataContext } from '../DataContext.jsx'
import { ReloadIcon } from '../components/Icons'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: center;
  max-height: calc(100vh - 340px);
  overflow-y: scroll;
  overflow-x: hidden;
  max-width: 100vw;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background-color: hsl(193, 10%, 25%);
  }

  &::-webkit-scrollbar-thumb {
    background-color: hsl(193, 30%, 50%);
  }
`

const EmployesCard = styled.table`
  border: 3px solid #757575;
  border-radius: 6px;
  padding: 10px;
  & tbody > tr {
      outline: 1px solid #aaa;
      white-space: nowrap;

      &:first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
      
      &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }

  @media (max-width: 676px) {
    transform: scale(0.75);
  }
`

export default function Production(props) {
  // const { production, admins, employees, materials, updated } = props
  const { production, employees, materials, reloadProduction } = useContext(DataContext)
  const [filterName, setFilterName] = useState('')
  const [spinner, setSpinner] = useState(false)
  const [filterProduction, setFilterProduction] = useState(production)

  const UpdateFilter = ({ target }) => {
    setFilterName(target.value)
    // limpiamos el arreglo
    setFilterProduction([])

    // rellenar el state con el arreglo filtrado
    production.map(product => {
      const name = employees[employees.findIndex(employee => employee.id === product.idEmployee)].name.toLowerCase()

      if (name.includes(target.value.toLowerCase()) || target.value === '') {
        setFilterProduction(filterProduction => [...filterProduction, product])
      }
    })
  }

  const updated = () => {
    setSpinner(true)
    reloadProduction()
    setTimeout(() => {
      setSpinner(false)
    }, 1000)
  }

  return (
    <>
      <div className='block-reload'>
        <input type='text' placeholder='Nombre del empleado...' value={filterName} onChange={e => UpdateFilter(e)} />
        <ReloadIcon
          width='32'
          height='32'
          color={spinner ? '#dedede' : '#fafafa'}
          className={spinner ? 'spin spinner' : 'spin'}
          onClick={updated}
        />
      </div>
      <br />
      <span className='title'>Produccion</span>
      <br />
      <Container>
        <EmployesCard>
          <thead>
            <tr>
              {/* <th>Admin</th> */}
              <th>Empleado</th>
              <th>Precio</th>
              <th>Material</th>
              <th>kilos</th>
              <th>Fecha</th>
            </tr>
          </thead>
          <tbody>
            {filterProduction.map(prod => {
              console.warn(prod)

              return (
                <tr key={prod.date + prod.dateHour}>
                  {/* <td>{admins[admins.findIndex(admin => admin.id === prod.admin)]?.name}</td> */}
                  <td>{employees[employees.findIndex(employee => employee.id === prod.idEmployee)]?.pseudonimo}</td>
                  <td>${prod.price}</td>
                  <td>{materials[materials.findIndex(materials => materials.id === prod.material)]?.name}</td>
                  <td>{prod.kg}kg</td>
                  <td>{prod.date}</td>
                </tr>
              )
            })}
          </tbody>
        </EmployesCard>
      </Container>
    </>
  )
}

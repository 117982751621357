import React, { useState, useEffect, useContext } from 'react'
import { DataContext } from '../DataContext.jsx'
import { ReloadIcon } from '../components/Icons'
import styled from 'styled-components'

const Container = styled.div`
  max-height: calc(100vh - 340px);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background-color: hsl(193, 10%, 25%);
  }

  &::-webkit-scrollbar-thumb {
    background-color: hsl(193, 30%, 50%);
  }
`

const EmployesCard = styled.table`
  border: 3px solid #757575;
  border-radius: 6px;
  padding: 10px;
  & tbody > tr {
      outline: 1px solid #aaa;

      &:first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
      
      &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
`

export default function Employees() {
  const { employees, reloadEmployees } = useContext(DataContext)
  const [filterName, setFilterName] = useState('')
  const [spinner, setSpinner] = useState(false)
  const [filterEmployees, setFilterEmployees] = useState(employees)

  const UpdateFilter = ({ target }) => {
    setFilterName(target.value)
    // limpiamos el arreglo
    setFilterEmployees([])

    // rellenar el state con el arreglo filtrado
    employees.map(employee => {
      const name = employee.name.toLowerCase()
      if (name.includes(target.value.toLowerCase()) || target.value === '') {
        setFilterEmployees(filterEmployees => [...filterEmployees, employee])
      }
    })
  }

  useEffect(() => {
    setFilterEmployees(employees)
  }, [employees])

  const updated = () => {
    setSpinner(true)
    reloadEmployees()
    setTimeout(() => {
      setSpinner(false)
    }, 1000)
  }

  return (
    <>
      <div className='block-reload'>
        <input type='text' placeholder='Escriba el nombre...' value={filterName} onChange={UpdateFilter} />
        <ReloadIcon
          width='32'
          height='32'
          color={spinner ? '#dedede' : '#fafafa'}
          className={spinner ? 'spin spinner' : 'spin'}
          onClick={updated}
        />
      </div>
      <br />
      <span className='title'>Empleados</span>
      <br />
      <Container>
        <EmployesCard>
          <thead>
            <tr>
              <th>Apodo</th>
              <th>Nombre</th>
            </tr>
          </thead>
          <tbody>
            {filterEmployees.map(admin => (
              <tr key={admin.pseudonimo + admin.name}>
                <td>{admin.pseudonimo}</td>
                <td>{admin.name}</td>
              </tr>
            ))}
          </tbody>
        </EmployesCard>
      </Container>
    </>
  )
}
